import React, { useEffect } from 'react';
import { Link, navigate, PageProps } from 'gatsby';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';

import * as styles from './order-received.module.scss';
import Page, { PageBody, PageHeader } from '../components/page';
import useAPIHelper from '../hooks/api';
import {
  CURRENCY_SYMBOL_MAP,
  SUBSCRIPTION_PLAN_CONTENT_MAP,
} from '@common/constants';
import { SubscriptionType } from '@common/types';
import Spinner from '../components/spinner';

export interface CheckoutSuccessResponse {
  expires: number;
  subscriptionType: SubscriptionType;
  price: {
    amount: number;
    currency: 'gbp' | 'usd';
    interval: string;
  };
}

declare global {
  interface Window {
    gtag: any;
  }
}

// TODO: This page is awaiting design/content
const OrderReceivedPage = ({ location }: PageProps) => {
  const queryParams = new URLSearchParams(location.search);

  const { user } = useAuth0();
  const { useLambdaAPI, makeFetchLambdaPrivateConfig } = useAPIHelper();
  const [{ data, loading }, fetchCheckoutSuccess] =
    useLambdaAPI<CheckoutSuccessResponse>(
      {
        url: '/checkout-success',
      },
      { manual: true }
    );

  useEffect(() => {
    (async () => {
      try {
        await fetchCheckoutSuccess(
          await makeFetchLambdaPrivateConfig({
            params: {
              sessionId: queryParams.get('sessionId'),
              userId: user?.sub,
            },
          })
        );
      } catch {
        navigate('/');
      }
    })();
  }, []);

  useEffect(() => {
    if (!data?.price) return;

    window.gtag?.('event', 'conversion', {
      value: (data?.price.amount / 100).toFixed(2),
      send_to: 'AW-11022163338/fEJZCML1_ocYEIq744cp',
      currency: data.price.currency.toUpperCase(),
      transaction_id: queryParams.get('sessionId'),
    });
  }, [data?.price, queryParams]);

  return (
    <Page>
      <PageHeader location={location} />
      <PageBody>
        <div className={styles.OrderReceived__Body}>
          <h2 className={styles.OrderReceived__Status}>
            Your subscription was successful,
            <br />
            Welcome to Mirosign
          </h2>
          <article className={styles.OrderReceived__card}>
            {loading ? (
              <Spinner isLoading={loading} />
            ) : data ? (
              <>
                <h3>Details</h3>
                <table className={styles.OrderReceived__Details}>
                  <tbody>
                    <tr>
                      <th>Plan</th>
                      <td>
                        {
                          SUBSCRIPTION_PLAN_CONTENT_MAP[data.subscriptionType]
                            ?.label
                        }
                      </td>
                    </tr>
                    <tr>
                      <th>Price</th>
                      <td>
                        {CURRENCY_SYMBOL_MAP[data.price.currency]}
                        {data.price.amount / 100} per {data.price.interval}
                      </td>
                    </tr>
                    <tr>
                      <th>Next renewal</th>
                      <td>
                        {
                          new Date(data.expires)
                            .toLocaleString('en-GB', { timeZone: 'UTC' })
                            .split(',')[0]
                        }
                      </td>
                    </tr>
                  </tbody>
                </table>
              </>
            ) : null}
            <p className={styles.OrderReceived__CTA}>
              <Link to="/" className="button button--block">
                Design your signature
              </Link>
            </p>
          </article>
        </div>
      </PageBody>
    </Page>
  );
};

export default withAuthenticationRequired(OrderReceivedPage);
